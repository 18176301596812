import React from "react";
import Logo from "./images/Coin-Logov2.svg";

class Footer extends React.Component {
  render() {
    return (
      <footer className="footer">
        <div className="footer__top">
          <div className="footer__top__logo">
            <img src={Logo} alt="brand logo" />
            <span className="text text--medium">Squooshie</span>
          </div>
        </div>
        <div className="footer__bottom">
          <div className="footer__bottom__copyright text text--small text--regular">
            ©2024 Squooshie. All rights reserved.
          </div>
          <div className="footer__bottom__social-media">
            <a
              href="https://t.me/+fovaz_BVgnE2ZTJk"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg className="social-icon">
                <use xlinkHref="images/social-media-sprite.svg#telegram" />
              </svg>
            </a>
            <a
              href="https://x.com/Squooshie1"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg className="social-icon">
                <use xlinkHref="images/social-media-sprite.svg#twitter" />
              </svg>
            </a>
            <svg className="social-icon">
              <use xlinkHref="images/social-media-sprite.svg#facebook" />
            </svg>
            <svg className="social-icon">
              <use xlinkHref="images/social-media-sprite.svg#instagram" />
            </svg>
            <svg className="social-icon">
              <use xlinkHref="images/social-media-sprite.svg#youtube" />
            </svg>
            <svg className="social-icon">
              <use xlinkHref="images/social-media-sprite.svg#linkedin" />
            </svg>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
