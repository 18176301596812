import React from "react";

class Roadmap extends React.Component {
  render() {
    return (
      <div className="roadmap">
        <div className="roadmap-header">
          <h2>Roadmap</h2>
          <p className="text text--standart text--regular">
            Let’s look at the path SQUO will take on its journey to take over
            the DeFi world.
          </p>
        </div>
        <div className="road-body">
          <div className="card card--secondary card-red">
            <div className="card__header">
              <h3>Q4 2024 (October - December 2024)</h3>
            </div>
            <ul>
              <li>Partnerships</li>
              <li>SQUO Listing</li>
              <li>Audit</li>
              <li>Liquidity Lock</li>
              <li>Squooshie Launch</li>
              <li>Website and Whitepaper Release</li>
              <li>Launch of Telegram and Twitter Platforms</li>
            </ul>
          </div>
          <div className="card card--white card-yellow">
            <div className="card__header">
              <h3>Q1 2025 (January - March 2025)</h3>
            </div>
            <ul>
              <li>
                Publishing exclusive NFT collections and integrating with
                Squooshie Coin
              </li>
              <li>
                Community events with surprise Squooshie forms and rewards
              </li>
              <li>$SQUO Exchange Listing</li>
              <li>
                Squooshie Promotion (Blog posts, videos, and social media
                campaigns)
              </li>
              <li>Partnerships with other projects and influencers</li>
            </ul>
          </div>
          <div className="card card--white card-green">
            <div className="card__header">
              <h3>Q2 2025 (July - September 2025)</h3>
            </div>
            <ul>
              <li>$SQUO Tier 1 Exchange Listing</li>
              <li>Launch of Squooshie Swap (DEX)</li>
              <li>
                Organizing educational programs, webinars, and events for
                community members
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default Roadmap;
