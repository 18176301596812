import React from "react";
import MediaContainer from "./Media_container";
import GridChoose from "./grid_choose";

class Why_crypto extends React.Component {
  render() {
    return (
      <div className="why-crypto">
        <MediaContainer></MediaContainer>
        <GridChoose></GridChoose>
      </div>
    );
  }
}

export default Why_crypto;
