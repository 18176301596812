import React from "react";

class Roadmap extends React.Component {
  render() {
    return (
      <div className="roadmap">
        <div className="whitepaper-title">
          <h2>Squooshie Coin (SQUO) Whitepaper</h2>
        </div>
        <div className="roadmap-body">
          <h3>1. Introduction</h3>
          <h4>1.1. Project Overview</h4>
          <p>
            Squooshie Coin (SQUO) is a digital asset designed to introduce an
            innovative meme coin experience to the world of cryptocurrency. The
            core feature of Squooshie Coin is its ability to offer users a
            dynamic coin experience that is constantly changing and full of
            surprises. Each Squooshie Coin "transforms" at specific intervals,
            providing different advantages, giving its holders continuous
            opportunities for discovery and rewards.
          </p>
          <h4>1.2. Vision and Mission</h4>
          <p>
            Vision: To create a fun, innovative, and community-driven ecosystem
            in the crypto world.
            <br />
            Mission: To make Squooshie Coin a standout meme coin with dynamic
            features and a community-centric approach, offering users a fun and
            profitable experience.
          </p>

          <h3>2. Squooshie Coin Features</h3>
          <h4>2.1. Dynamic Transformations</h4>
          <p>
            The core feature of Squooshie Coin is its ability to change shape
            over time or based on user interactions. Each transformation affects
            the coin's features and rewards:
          </p>
          <ul>
            <li>
              <strong>Soft Squooshie:</strong> Provides NFT rewards.
            </li>
            <li>
              <strong>Hard Squooshie:</strong> Allows for staking and earning
              passive income.
            </li>
            <li>
              <strong>Shiny Squooshie:</strong> Offers entry into special
              raffles and airdrops.
            </li>
            <li>
              <strong>Golden Squooshie:</strong> Unlocks VIP community
              membership and exclusive opportunities.
            </li>
          </ul>

          <h4>2.2. NFT Integration</h4>
          <p>
            Squooshie Coin holders can customize the features of their coins
            through special NFTs. These NFTs modify the dynamic characteristics
            of the coin and offer users additional benefits.
          </p>

          <h4>2.3. Deflationary Mechanism</h4>
          <p>
            Certain Squooshie forms may lead to token burns, reducing the supply
            and contributing to an increase in token value over time.
          </p>

          <h3>3. Technological Infrastructure</h3>
          <h4>3.1. Smart Contracts</h4>
          <p>
            Squooshie Coin is powered by Bitgert-based smart contracts. These
            contracts manage the coin’s transformation mechanism and reward
            systems. Security audits and tests have been conducted to prioritize
            the safety of users.
          </p>

          <h4>3.2. Dynamic Features</h4>
          <p>
            The coin’s form changes when users complete specific tasks or when a
            set time period elapses. Each new form offers different rewards and
            features.
          </p>
        </div>
      </div>
    );
  }
}

export default Roadmap;
